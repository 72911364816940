import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-main-preloader',
    templateUrl: './main-preloader.component.html',
    styleUrls: ['./main-preloader.component.scss'],
})
export class MainPreloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
